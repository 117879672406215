import { ref, Ref } from 'vue'
import {
  IAggregateSaleData,
  IAggregateSaleDataParams
} from '@/service/sale/reportAggregate/type'
import { getAggregateSaleData } from '@/service/sale/reportAggregate'

export default function useAggregateSaleData(
  params: Ref<IAggregateSaleDataParams>
): [Ref<IAggregateSaleData>, () => Promise<void>] {
  const aggregateSaleData = ref<IAggregateSaleData>({
    totMoneyList: [],
    settleValueList: [],
    totalSettleValue: 0,
    totalMoney: 0,
    totalAveragePrice: 0,
    totalSingular: 0,
    timeRange: ''
  })

  // 获取主机汇总数据
  const getAggregateSaleDataAsync = async () => {
    const res = await getAggregateSaleData(params.value)
    aggregateSaleData.value = res.data
  }

  return [aggregateSaleData, getAggregateSaleDataAsync]
}
