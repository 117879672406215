import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import {
  IAggregateSaleData,
  IAggregateSaleDataParams,
  IAggregateSaleContrastData,
  ISaleRank,
  IAggregateSaleTrendParams,
  IAggregateSaleTrend
} from './type'

enum DashboardApi {
  getAggregateSaleData = '/saleData/mortarSaleData',
  getAggregateSaleContrastData = '/saleData/mortarSaleContrastData',
  getAggregateSaleRank = '/saleData/mortarSaleRank',
  getAggregateSaleTrend = '/saleData/mortarSaleTrend'
}

// 骨料汇总及各料类销售数据
export function getAggregateSaleData(
  data: IAggregateSaleDataParams
): Promise<IRequestResult<IAggregateSaleData>> {
  return hyRequest.post<IRequestResult<IAggregateSaleData>>({
    url: DashboardApi.getAggregateSaleData,
    data
  })
}

//骨料销售对比数据
export function getAggregateSaleContrastData(
  data: IAggregateSaleDataParams
): Promise<IRequestResult<IAggregateSaleContrastData>> {
  return hyRequest.post<IRequestResult<IAggregateSaleContrastData>>({
    url: DashboardApi.getAggregateSaleContrastData,
    data
  })
}
//骨料销售客户金额日排行榜
export function getAggregateSaleRank(
  data: IAggregateSaleDataParams
): Promise<IRequestResult<ISaleRank>> {
  return hyRequest.post<IRequestResult<ISaleRank>>({
    url: DashboardApi.getAggregateSaleRank,
    data: data
  })
}

//骨料销售趋势数据
export function getAggregateSaleTrend(
  data: IAggregateSaleTrendParams
): Promise<IRequestResult<IAggregateSaleTrend>> {
  return hyRequest.post<IRequestResult<IAggregateSaleTrend>>({
    url: DashboardApi.getAggregateSaleTrend,
    data: data
  })
}
