import _slicedToArray from "D:/code/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.number.constructor.js";
import { defineComponent, onMounted, ref } from 'vue';
import dayjs from 'dayjs';
import useSalePlan from './hooks/useSalePlan';
export default defineComponent({
  name: 'PlanDetail',
  components: {},
  props: {
    type: {
      type: Number,
      required: true
    }
  },
  setup: function setup() {
    var params = ref({
      date: dayjs().format('YYYY/MM/DD'),
      type: 0 //时间类型 0日数据 1周数据 2月数据
    });
    var confrimDate = function confrimDate(val) {
      params.value.date = val;
      getConcreteSalePlanDateAsync();
    };
    var _useSalePlan = useSalePlan(params),
      _useSalePlan2 = _slicedToArray(_useSalePlan, 2),
      salePlanData = _useSalePlan2[0],
      getConcreteSalePlanDateAsync = _useSalePlan2[1];
    onMounted(function () {
      getConcreteSalePlanDateAsync();
    });
    var message = ref('<h1 style="color:orange;">你好，李银河</h1>');
    return {
      params: params,
      salePlanData: salePlanData,
      message: message,
      confrimDate: confrimDate
    };
  }
});