import { ref, Ref } from 'vue'
import { IMortarPriceForStoneItem } from '@/service/sale/salePrice/type'
import {
  getMortarSales,
  getMortarSalesPriceForStone
} from '@/service/sale/salePrice'

export default function useMortarList(
  params: Ref<{ stoneName: string }>
): [
  Ref<{ stone_name: string }[]>,
  Ref<IMortarPriceForStoneItem[]>,
  () => Promise<void>,
  () => Promise<void>
] {
  const mortarList = ref<{ stone_name: string }[]>([])

  // 获取主机汇总数据
  const getMortarSalesAsync = async () => {
    const res = await getMortarSales()
    mortarList.value = res.data.list
  }

  const mortarPriceList = ref<IMortarPriceForStoneItem[]>([])

  const getMortarPriceForStoneAsync = async () => {
    const res = await getMortarSalesPriceForStone(params.value)
    mortarPriceList.value = res.data.list
  }

  return [
    mortarList,
    mortarPriceList,
    getMortarSalesAsync,
    getMortarPriceForStoneAsync
  ]
}
