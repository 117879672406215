import { ref, Ref } from 'vue';
import {
  IAggregateSaleCount,
  IAggregateSaleDataParams,
  IAgProdSaleData
} from '@/service/sale/productAndSale/type';
import {
  getAggregateSaleCount,
  getProdCmSaleVlBar
} from '@/service/sale/productAndSale';

export default function useMortarSaleTrend(
  params: Ref<IAggregateSaleDataParams>
): [
  Ref<IAgProdSaleData>,
  Ref<IAgProdSaleData>,
  () => Promise<void>,
  () => Promise<void>,
  () => Promise<void>,
  Ref<IAggregateSaleCount[]>
] {
  const aggregateSaleTrendData = ref<IAgProdSaleData>({
    sale: [],
    prod: [],
    xAxis: [],
    stock: []
  });
  const aggregateSaleTrendYearData = ref<IAgProdSaleData>({
    sale: [],
    prod: [],
    xAxis: [],
    stock: []
  });
  // 获取主机汇总数据
  const getProdCmSaleVlBarAsync = async () => {
    let num = 30;
    let type = 0;
    switch (params.value.type) {
      // 日
      case 0:
        num = 30;
        type = 0;
        break;
      // 周
      case 1:
        num = 30;
        type = 1;
        break;
      // 月
      case 2:
        num = 12;
        // num = 30
        type = 2;
        break;
      // 年
      case 3:
        num = 3;
        // num = 12
        type = 3;
        break;
      // 自定义
      case 4:
        num = 10;
        type = 4;
        break;
    }
    const res = await getProdCmSaleVlBar({
      date: params.value.date,
      type: type
      // rangeType: type,
      // rangeNum: num
    });
    aggregateSaleTrendData.value = res.data;
  };
  const getProdCmSaleVlBarYearAsync = async () => {
    const res = await getProdCmSaleVlBar({
      date: params.value.date,
      type: 3
      // rangeType: -1,
      // rangeNum: 3
    });
    aggregateSaleTrendYearData.value = res.data;
  };

  const aggregateSaleCountData = ref<IAggregateSaleCount[]>([]);
  const getAggregateSaleCountAsync = async () => {
    const res = await getAggregateSaleCount(params.value);
    // 先添加合计数据
    const countList = res.data.list;
    countList.push({
      customer_id: 9999999999,
      customer: '额外添加的数据为了方便计算',
      car_cnt: '0',
      count: 0,
      customer_pay: '0',
      net_weight: '0',
      stone: 'fake料类名称'
    });
    const newCountList: IAggregateSaleCount[] = [];
    let currentCustomerId = 0;
    let tmpList: IAggregateSaleCount[] = [];
    countList.forEach((item, index) => {
      console.log(item, index, countList.length, 'caaaa');
      if (currentCustomerId !== 0 && item.customer_id !== currentCustomerId) {
        // if (countList.length - 1 === index) {
        //   tmpList.push(item);
        // }
        const moneyArr: number[] = tmpList.map((item) =>
          parseFloat(item.customer_pay)
        );
        const weightArr: number[] = tmpList.map((item) =>
          parseFloat(item.net_weight)
        );
        const carNumArr: number[] = tmpList.map((item) =>
          parseFloat(item.car_cnt)
        );
        // 计算统计数据
        const tmpData: IAggregateSaleCount = {
          stone: '合计',
          customer: tmpList[0].customer,
          count: 0,
          customer_id: tmpList[0].customer_id,
          customer_pay: moneyArr
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            )
            .toFixed(2),
          net_weight: weightArr
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            )
            .toFixed(2),
          car_cnt: `${carNumArr.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )}`
        };
        if (countList.length - 1 === index) {
          // newCountList.push(item);
          newCountList.push(tmpData);
        } else {
          newCountList.push(tmpData);
          newCountList.push(item);
        }
        if (item.customer_id !== currentCustomerId) {
          tmpList = [];
        }
      } else {
        newCountList.push(item);
      }
      tmpList.push(item);
      currentCustomerId = item.customer_id;
    });

    // 对数据进行处理，方便展示时合并单元格
    const list = newCountList.reverse();

    let sum = 1;
    currentCustomerId = 0;

    const newList: IAggregateSaleCount[] = [];
    list.forEach((item, index) => {
      if (index !== 0) {
        if (item.customer_id !== currentCustomerId) {
          sum = 1;
        } else {
          sum++;
        }
      }
      item.count = sum;

      currentCustomerId = item.customer_id;
      newList.push(item);
    });

    aggregateSaleCountData.value = newList.reverse();

    console.log(aggregateSaleCountData.value, 'aggregateSaleCountData');
  };

  return [
    aggregateSaleTrendData,
    aggregateSaleTrendYearData,
    getProdCmSaleVlBarAsync,
    getProdCmSaleVlBarYearAsync,

    getAggregateSaleCountAsync,
    aggregateSaleCountData
  ];
}
