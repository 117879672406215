import hyRequest, { hyRequest2 } from '@/service'
import { IRequestResult, IRequestResult2 } from '@/service/types'
import {
  IAggregateSaleData,
  IAggregateSaleDataParams,
  IAggregateSaleContrastData,
  IAggregateSalePrice,
  ISaleRank,
  IAggregateSaleTrendParams,
  IAggregateSaleTrend
} from './type'

enum DashboardApi {
  getAggregateSaleData = '/saleData/aggregateSaleData',
  getAggregateSaleContrastData = '/saleData/aggregateSaleContrastData',
  getAggregateSaleCommercialData = '/commercialSalesPrice/highestPrice',
  getAggregateSaleCommercialDataList = '/commercialSalesPrice/salesPriceList',
  getAggregateSalemortarData = '/mortarSalesPrice/highestPrice',
  getAggregateSalemortarDataList = '/mortarSalesPrice/salesPriceList',
  getAggregateSalePriceData = '/aggregateSalesPrice/newestPrice',
  getAggregateSalehistoryPriceTrend = '/aggregateSalesPrice/historyPriceTrend',
  findDayStoneSales = '/report/sales/findDayStoneSales',
  getAggregateSaleRank = '/saleData/aggregateSaleRank',
  getAggregateSaleTrend = '/saleData/aggregateSaleTrend'
}

// 骨料汇总及各料类销售数据
export function getAggregateSaleData(
  data: IAggregateSaleDataParams
): Promise<IRequestResult<IAggregateSaleData>> {
  return hyRequest.post<IRequestResult<IAggregateSaleData>>({
    url: DashboardApi.getAggregateSaleData,
    data
  })
}
// 骨料汇总及各料类销售数据表格版数据
export function findDayStoneSales(startTime: string): Promise<IRequestResult2> {
  return hyRequest.get<IRequestResult2>({
    url: `${DashboardApi.findDayStoneSales}?startTime=${startTime}`
  })
}

//骨料销售对比数据
export function getAggregateSaleContrastData(
  data: IAggregateSaleDataParams
): Promise<IRequestResult<IAggregateSaleContrastData>> {
  return hyRequest.post<IRequestResult<IAggregateSaleContrastData>>({
    url: DashboardApi.getAggregateSaleContrastData,
    data
  })
}

//商砼销售对比数据
export function getAggregateSaleCommercialData(): Promise<
  IRequestResult<IAggregateSaleContrastData>
> {
  return hyRequest.post<IRequestResult<IAggregateSaleContrastData>>({
    url: DashboardApi.getAggregateSaleCommercialData
  })
}

//商砼销售单条数据
export function getAggregateSaleCommercialDataList(data: {
  stone_id: number
  stone_spec_id: number
}): Promise<IRequestResult<IAggregateSaleContrastData>> {
  return hyRequest.post<IRequestResult<IAggregateSaleContrastData>>({
    url: DashboardApi.getAggregateSaleCommercialDataList,
    data
  })
}

//砂浆销售对比数据
export function getAggregateSalemortarData(): Promise<
  IRequestResult<IAggregateSaleContrastData>
> {
  return hyRequest.post<IRequestResult<IAggregateSaleContrastData>>({
    url: DashboardApi.getAggregateSalemortarData
  })
}

//砂浆销售单条数据
export function getAggregateSalemortarDataList(data: {
  stone_id: number
  stone_spec_id: number
}): Promise<IRequestResult<IAggregateSaleContrastData>> {
  return hyRequest.post<IRequestResult<IAggregateSaleContrastData>>({
    url: DashboardApi.getAggregateSalemortarDataList,
    data
  })
}

// 骨料汇总及各料类销售价格
export function getAggregateSalePriceData(): Promise<
  IRequestResult<IAggregateSalePrice>
> {
  return hyRequest.get<IRequestResult<IAggregateSalePrice>>({
    url: DashboardApi.getAggregateSalePriceData
  })
}

// 单个料类销售价格
export function getAggregateSalePriceOneData(data: {
  stone_id: number
  stone_spec_id: number
}): Promise<IRequestResult<IAggregateSalePrice>> {
  return hyRequest.post<IRequestResult<IAggregateSalePrice>>({
    url: DashboardApi.getAggregateSalehistoryPriceTrend,
    data: data
  })
}

//骨料销售客户金额日排行榜
export function getAggregateSaleRank(
  data: IAggregateSaleDataParams
): Promise<IRequestResult<ISaleRank>> {
  return hyRequest.post<IRequestResult<ISaleRank>>({
    url: DashboardApi.getAggregateSaleRank,
    data: data
  })
}

//骨料销售趋势数据
export function getAggregateSaleTrend(
  data: IAggregateSaleTrendParams
): Promise<IRequestResult<IAggregateSaleTrend>> {
  return hyRequest.post<IRequestResult<IAggregateSaleTrend>>({
    url: DashboardApi.getAggregateSaleTrend,
    data: data
  })
}
