import { ref, Ref } from 'vue'
import {
  IProductionData,
  IProductionDataParams
} from '@/service/productionData/type'
import { getProductionData } from '@/service/productionData'

export default function useProductionData(
  params: Ref<IProductionDataParams>
): [Ref<IProductionData>, () => Promise<void>] {
  const productionData = ref<IProductionData>({
    sum: 0,
    tonEle: '',
    totalEle: ''
  })

  // 获取产量与耗电量
  const getProductionDataAsync = async () => {
    const res = await getProductionData(params.value)
    productionData.value = res.data
  }

  return [productionData, getProductionDataAsync]
}
