import { ref, Ref } from 'vue'
import { IMortarPriceForStoneItem } from '@/service/sale/salePrice/type'
import {
  getCommercialSales,
  getCommercialSalesPriceForStone
} from '@/service/sale/salePrice'

export default function useShangTongList(
  params: Ref<{ stoneName: string }>
): [
  Ref<{ stone_name: string }[]>,
  Ref<IMortarPriceForStoneItem[]>,
  () => Promise<void>,
  () => Promise<void>
] {
  const shangTongList = ref<{ stone_name: string }[]>([])

  const getCommercialSalesAsync = async () => {
    const res = await getCommercialSales()
    shangTongList.value = res.data.list
  }
  const shangTongPriceList = ref<IMortarPriceForStoneItem[]>([])

  const getCommercialPriceForStoneAsync = async () => {
    const res = await getCommercialSalesPriceForStone(params.value)
    shangTongPriceList.value = res.data.list
  }

  return [
    shangTongList,
    shangTongPriceList,
    getCommercialSalesAsync,
    getCommercialPriceForStoneAsync
  ]
}
