import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8fd6bf66"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "plan-detail"
};
var _hoisted_2 = {
  class: "container"
};
var _hoisted_3 = {
  class: "box"
};
var _hoisted_4 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_c_date_header_with_btn = _resolveComponent("c-date-header-with-btn");
  var _component_c_no_data = _resolveComponent("c-no-data");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_c_date_header_with_btn, {
    ref: "dateRef",
    type: _ctx.params.type,
    showBtn: false,
    onConfirmDate: _ctx.confrimDate
  }, null, 8, ["type", "onConfirmDate"]), _createElementVNode("div", _hoisted_3, [_ctx.salePlanData.content ? (_openBlock(), _createElementBlock("pre", _hoisted_4, "          " + _toDisplayString(_ctx.salePlanData.content) + "\n        ", 1)) : (_openBlock(), _createBlock(_component_c_no_data, {
    key: 1
  }))])])]);
}