import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import { IDetailParams, ISaleDetailData } from './type'

enum DashboardApi {
  getAggregateSaleDetail = '/saleData/aggregateSaleDetail',
  getCommercialSaleDetail = '/saleData/commercialSaleDetail',
  getMortarSaleDetail = '/saleData/mortarSaleDetail'
}

// 查询骨料销售明细
export function getAggregateSaleDetail(
  data: IDetailParams
): Promise<IRequestResult<ISaleDetailData>> {
  return hyRequest.post<IRequestResult<ISaleDetailData>>({
    url: DashboardApi.getAggregateSaleDetail,
    data
  })
}
// 查询商砼销售明细
export function getCommercialSaleDetail(
  data: IDetailParams
): Promise<IRequestResult<ISaleDetailData>> {
  return hyRequest.post<IRequestResult<ISaleDetailData>>({
    url: DashboardApi.getCommercialSaleDetail,
    data
  })
}
// 查询砂浆销售明细
export function getMortarSaleDetail(
  data: IDetailParams
): Promise<IRequestResult<ISaleDetailData>> {
  return hyRequest.post<IRequestResult<ISaleDetailData>>({
    url: DashboardApi.getMortarSaleDetail,
    data
  })
}
