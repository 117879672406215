import { ref, Ref } from 'vue'
import { IAggregateSalePriceForStoneItem } from '@/service/sale/salePrice/type'
import { getHistoryPriceTrendForStone } from '@/service/sale/salePrice'
import dayjs from 'dayjs'

export default function useAggregateHistoryPriceForStone(
  params: Ref<{ stoneName: string; stoneSpecName: string }>
): [Ref<IAggregateSalePriceForStoneItem[]>, () => Promise<void>] {
  const aggregatePriceTrend = ref<IAggregateSalePriceForStoneItem[]>([])

  const getPriceTrendAsync = async () => {
    const res = await getHistoryPriceTrendForStone(params.value)
    aggregatePriceTrend.value = res.data.list.map((item) => {
      item.update_time = dayjs(item.update_time).format('YYYY/MM/DD')
      return item
    })
  }

  return [aggregatePriceTrend, getPriceTrendAsync]
}
