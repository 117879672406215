import { ref, Ref } from 'vue'
import {
  IAggregateSaleDataParams,
  ISaleRankItem
} from '@/service/sale/reportAggregate/type'
import { getAggregateSaleRank } from '@/service/sale/reportAggregate'

export default function useAggregateSaleRank(
  params: Ref<IAggregateSaleDataParams>
): [Ref<ISaleRankItem[]>, () => Promise<void>] {
  const aggregateSaleRankData = ref<ISaleRankItem[]>([])

  // 获取主机汇总数据
  const getAggregateSaleRankAsync = async () => {
    const res = await getAggregateSaleRank(params.value)
    aggregateSaleRankData.value = res.data.list
  }

  return [aggregateSaleRankData, getAggregateSaleRankAsync]
}
