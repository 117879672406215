import { ref, Ref } from 'vue'
import { IAggregateSaleDataParams } from '@/service/sale/reportShangTong/type'
import { getConcreteSalePlanDate } from '@/service/sale/reportShangTong'

export default function useSalePlan(params: Ref<IAggregateSaleDataParams>): [
  Ref<{
    plan_num: number
    content: string
  }>,
  () => Promise<void>
] {
  const salePlanData = ref({
    plan_num: 0,
    content: ''
  })

  // 获取主机汇总数据
  const getConcreteSalePlanDateAsync = async () => {
    const res = await getConcreteSalePlanDate({
      date: params.value.date
    })
    salePlanData.value = res.data
  }

  return [salePlanData, getConcreteSalePlanDateAsync]
}
