import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2702b816"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "aggregate-daily-sales-report"
};
var _hoisted_2 = {
  class: "container"
};
var _hoisted_3 = {
  class: "num"
};
var _hoisted_4 = {
  class: "desc"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_c_type_tab = _resolveComponent("c-type-tab");
  var _component_c_date_header_with_btn = _resolveComponent("c-date-header-with-btn");
  var _component_van_grid_item = _resolveComponent("van-grid-item");
  var _component_van_grid = _resolveComponent("van-grid");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_c_type_tab, {
    onConfirmType: _ctx.confirmType
  }, null, 8, ["onConfirmType"]), _createVNode(_component_c_date_header_with_btn, {
    ref: "dateRef",
    type: _ctx.params.type,
    "show-btn": false,
    onConfirmDate: _ctx.confrimDate
  }, null, 8, ["type", "onConfirmDate"]), _createVNode(_component_van_grid, {
    class: "grid",
    gutter: 10,
    "column-num": 3
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaryData, function (item) {
        return _openBlock(), _createBlock(_component_van_grid_item, {
          key: item.material_name
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.emptyStringFormat(item.num)), 1), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.emptyStringFormat(item.desc)), 1)];
          }),
          _: 2
        }, 1024);
      }), 128))];
    }),
    _: 1
  })])]);
}