import { computed, ref, Ref } from 'vue'
import {
  IDetailParams,
  ISaleDetailItem,
  ITotalData
} from '@/service/sale/reportDetail/type'
import {
  getAggregateSaleDetail,
  getCommercialSaleDetail,
  getMortarSaleDetail
} from '@/service/sale/reportDetail'

export default function useMortarSaleContrastData(
  params: Ref<IDetailParams>,

  type: Ref<'aggregate' | 'shangTong' | 'mortar'>
): [
  Ref<ITotalData>,
  Ref<ISaleDetailItem[]>,
  Ref<boolean>,
  Ref<boolean>,
  () => Promise<void>,
  () => void
] {
  const loading = ref(false)
  const finished = ref(false)
  const totalData = ref<ITotalData>({
    rawMaterialMoney: 0,
    totalSettleValue: 0,
    totalAveragePrice: 0,
    totalFreight: 0,
    totalMoney: 0,
    totalSingular: 0
  })
  const list = ref<ISaleDetailItem[]>([])

  const getDetailAsync = async () => {
    try {
      loading.value = true

      if (type.value === 'aggregate') {
        const res = await getAggregateSaleDetail(params.value)
        totalData.value = res.data.totalData
        list.value.push(...res.data.detailPage.list)

        // 数据全部加载完成
        if (res.data.detailPage.totalRow === list.value.length) {
          finished.value = true
        }
      }
      if (type.value === 'shangTong') {
        const res = await getCommercialSaleDetail(params.value)
        totalData.value = res.data.totalData
        list.value.push(...res.data.detailPage.list)

        // 数据全部加载完成
        if (res.data.detailPage.totalRow === list.value.length) {
          finished.value = true
        }
      }
      if (type.value === 'mortar') {
        const res = await getMortarSaleDetail(params.value)
        totalData.value = res.data.totalData
        list.value.push(...res.data.detailPage.list)

        // 数据全部加载完成
        if (res.data.detailPage.totalRow === list.value.length) {
          finished.value = true
        }
      }
      // 加载状态结束
      loading.value = false
    } catch (error) {
      params.value.pageNumber -= 1
    }
  }
  const onLoad = () => {
    params.value.pageNumber += 1
    getDetailAsync()
  }
  return [totalData, list, loading, finished, getDetailAsync, onLoad]
}
