import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import { IProductionData, IProductionDataParams } from './type'

enum DashboardApi {
  getProductionData = '/product/qryProdKwh'
}

// 骨料汇总及各料类销售数据
export function getProductionData(
  data: IProductionDataParams
): Promise<IRequestResult<IProductionData>> {
  return hyRequest.post<IRequestResult<IProductionData>>({
    url: DashboardApi.getProductionData,
    data
  })
}
