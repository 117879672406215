import hyRequest from '@/service';
import { IRequestResult } from '@/service/types';
import {
  IAgProdSaleData,
  IAggregateSaleDataParams,
  IAggregateSaleContrastData,
  ISaleRank,
  IAggregateSaleTrendParams,
  IAggregateSaleTrend,
  IAggregateSaleCount
} from './type';

enum DashboardApi {
  getProdCmSaleVlBar = '/product/prodCmSaleVlBar',
  getAggregateSaleContrastData = '/saleData/mortarSaleContrastData',
  getAggregateSaleRank = '/saleData/mortarSaleRank',
  getAggregateSaleTrend = '/saleData/mortarSaleTrend',
  getAggregateSaleCount = '/saleData/customerAnalysisData'
}

// 产销库存柱状图
export function getProdCmSaleVlBar(
  data: IAggregateSaleDataParams
): Promise<IRequestResult<IAgProdSaleData>> {
  return hyRequest.post<IRequestResult<IAgProdSaleData>>({
    url: DashboardApi.getProdCmSaleVlBar,
    data
  });
}

//骨料销售对比数据
export function getAggregateSaleContrastData(
  data: IAggregateSaleDataParams
): Promise<IRequestResult<IAggregateSaleContrastData>> {
  return hyRequest.post<IRequestResult<IAggregateSaleContrastData>>({
    url: DashboardApi.getAggregateSaleContrastData,
    data
  });
}
//骨料销售客户金额日排行榜
export function getAggregateSaleRank(
  data: IAggregateSaleDataParams
): Promise<IRequestResult<ISaleRank>> {
  return hyRequest.post<IRequestResult<ISaleRank>>({
    url: DashboardApi.getAggregateSaleRank,
    data: data
  });
}

//骨料销售趋势数据
export function getAggregateSaleTrend(
  data: IAggregateSaleTrendParams
): Promise<IRequestResult<IAggregateSaleTrend>> {
  return hyRequest.post<IRequestResult<IAggregateSaleTrend>>({
    url: DashboardApi.getAggregateSaleTrend,
    data: data
  });
}

// 客户经营分析
export function getAggregateSaleCount(
  data: IAggregateSaleDataParams
): Promise<IRequestResult<{ list: IAggregateSaleCount[] }>> {
  return hyRequest.post<IRequestResult<{ list: IAggregateSaleCount[] }>>({
    url: DashboardApi.getAggregateSaleCount,
    data: data
  });
}
