import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import { useEmptyStringFormat } from '@/hooks/fn';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'PlanPart',
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var emptyStringFormat = useEmptyStringFormat();
    var router = useRouter();
    var toPlanDetail = function toPlanDetail() {
      router.push({
        name: 'PlanDetail'
      });
    };
    return {
      emptyStringFormat: emptyStringFormat,
      toPlanDetail: toPlanDetail
    };
  }
});