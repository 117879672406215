import { ref, Ref } from 'vue'
import { ISalePriceListItem } from '@/service/sale/salePrice/type'
import { getSalesPriceList } from '@/service/sale/salePrice'

export default function useAggregateList(): [
  Ref<ISalePriceListItem[]>,
  () => Promise<void>
] {
  const aggregateList = ref<ISalePriceListItem[]>([])

  // 获取主机汇总数据
  const getSalesPriceListAsync = async () => {
    const res = await getSalesPriceList()
    aggregateList.value = res.data.list
  }

  return [aggregateList, getSalesPriceListAsync]
}
